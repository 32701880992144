import React from "react";
import { graphql, StaticQuery } from 'gatsby';
import footerLogo from "../images/homepage/footer-logo.svg";
import githubIcon from "../images/homepage/github.svg";
import twitterLogo from "../images/homepage/twitter-logo.svg";
import facebookIcon from "../images/homepage/facebook.svg";
import linkedinIcon from "../images/homepage/linkedin.svg";
// import upArrow from "../images/up-arrow.svg";
import menuOpen from "../images/arrow_back_ios_white_24dp.svg";
import menuClosed from "../images/arrow_forward_ios_white_24dp.svg";
import pin from "../images/drawing2.svg";
// import closeIcon from "../images/close-icon.svg";

const MobileMenu = ({ hide }) => (
  <div className={"mobile-menu-open" + (hide ? " hide-mm" : " show-mm")}>
    <a href="/" aria-label="Home"><img src={footerLogo} alt=""/></a>
    <ul>
    <a className="default-a" href="/">
      <li>
          Home
      </li>
     </a>
     <a className="default-a" href="/contact">
        <li>
            Contact
        </li>
      </a>
    </ul>
  </div>
);

const TwitterPosts = () => {

  function isoStringToDate(s) {
    var date = new Date(
    s.replace(/^\w+ (\w+) (\d+) ([\d:]+) \+0000 (\d+)$/,
        "$1 $2 $4 $3 UTC"));
    return date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' }) +  " ∙ " + date.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric'   })   ;
  }
  function cutStr(yourString) {
    var maxLength = 120;
    var trimmedString = yourString.substr(0, maxLength);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
    return trimmedString + '...';
  }
  return(
    <StaticQuery
    query={graphql`
      query TwitterPosts {
        allTwitterStatusesUserTimelineGetPosts {
          edges{
            node{
              full_text
              created_at
              id_str
            }
          }
        }
      }
    `}
    render={(data) => (
        <div>
          {
            data.allTwitterStatusesUserTimelineGetPosts.edges.map((item, i) => (
              <div key={i}>
                <div className="tweet">{cutStr(item.node.full_text)}
                <div>
                  <a href={`https://twitter.com/i/web/status/${item.node.id_str}`} className="more" target="__blank">Read more</a>
                </div>
                </div>
                
                <hr/>
                <div className="date">{isoStringToDate(item.node.created_at)}</div>
                <br/>
                
                
              </div>
              

              ) 
            )          
          }
          </div>
      )}
  />
  
)}

class Footer extends React.Component {
  state = {
    showMobileMenu: false,
    arrowClicked: false
  };
  render() {
    const { style, withContainer } = this.props;
    const { showMobileMenu, arrowClicked } = this.state;
    return (
      <footer style={style}>
        <div className={withContainer ? "container" : null}>
          <div className="short-info">
            <img src={footerLogo} alt=""/>
            <p>
              We should really put something meaningful here, and make those footer links work.
              It is hard to give ETA to yourself?!
            </p>
          </div>

          <div className="footer-links">
            <div>
              <div className="office"> <img  className="loc" src={pin} alt=""/>Office 1</div>
              <div> Winterfell</div>

              <br/>
              <div>Nikolj Pazar 48</div>
              <div>Bijelo Polje 84000</div>
              <div>Montenegro</div>

            </div>
            <div>
              <div className="office"> <img  className="loc" src={pin} alt=""/><div><span>Office 2 </span><br/><span>King's Landing</span></div> </div>
              <br/>
              <div>Visariona Borilovića 8 1/1</div>
              <div>Podgorica 81000</div>
              <div>Montenegro</div>
            </div>
          </div>
          <div className="footer-social">
            <h5>
              <img src={twitterLogo} alt="" /> Latest Tweets
            </h5>
           
           <TwitterPosts/>
          </div>
        </div>
        <div className={withContainer ? "container social-cont" : ""}>
          <div className="social-icons">
            <a href="https://facebook.com/bixbit.me" target="__blank">
              <img src={facebookIcon} alt=""/>
            </a>
            <a href="https://github.com/bixbit-me" target="__blank">
              <img src={githubIcon} alt=""/>
            </a>
           
            <a href="https://linkedin.com/company/bixbit-me" target="__blank"> 
              <img src={linkedinIcon} alt=""/>
            </a>
          </div>
        </div>
        {arrowClicked ? <MobileMenu hide={!showMobileMenu} /> : null}

        <div className="mobile-menu-closed">
          <span role="button" tabIndex={0}
            className={(showMobileMenu ? "red" : "green") + "-circle"}
            onClick={() => {
                this.setState(prev => ({
                  showMobileMenu: !prev.showMobileMenu,
                  arrowClicked: true
                }))
                const html = document.querySelector('html')
                html.classList.toggle("handle-scroll");
              }
            }
            onKeyUp={()=>void 0}
          >
            <img src={showMobileMenu ? menuClosed : menuOpen} alt=""/>
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
