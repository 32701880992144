import React from "react";

import logo from "../images/homepage/header-logo.svg";



class Header extends React.Component {
  state = {
    isMenuOpen: false,
    isMouseOnMenu: false,
    hovered:""
  };
  mouseLeaveHandler = () => {
    this.setState({
      isMouseOnMenu: false
    });
    setTimeout(() => {
      this.setState(prev => {
        if (!prev.isMouseOnMenu) {
          return {
            isMenuOpen: false
          };
        }
      });
    }, 100);
  };

  mouseEnterHandler = () => {
    this.setState({
      isMenuOpen: true,
      isMouseOnMenu: true
    });
  };
  render() {
    // const SelectionTriangle = ({width = "101"}) => (
    //   <svg height="65" width={width} className={`. ${this.props.selected}`}>
    //     <polygon
    //       points="1,2 100,30 100,2"
    //       className="opposite-triangle-svg"
    //     />
    //     Sorry, your browser does not support inline SVG.
    //   </svg>
    // );
    return (
      <header
        className={"site-header" + (this.props.className ? ` ${this.props.className}` : "")}
        style={this.props.style}
      >
        <section className="header-logo">
          <a href="/">
            <img src={logo} alt=""/>
          </a>
        </section>

        <nav className={`header-navigation${this.props.dark ? "-dark" : ""} PrimaryNav with-indicator`}>
          <a href="/" className={`Nav-item ${this.props.selected === 'home' && "is-active"}`}>
            {/* {this.state.hovered === "home" || (this.props.selected === 'home' && this.state.hovered === "") ?
            <SelectionTriangle />
            : null
            } */}
            Home
            </a>
            
          {/* <span className={`solutions-menu Nav-item ${this.props.selected === 'solution' && "is-active"}`}> */}
            
           {/* {this.state.isMenuOpen && ( */}
              {/* <> */}
               {/* <div className="triangle-container"> */}
                 {/* <svg height="65" width="205" className="dropdwn"> */}
                  
                  {/* { <polygon */}
                    {/* points="1,2 199,60 1,60" */}
                    {/* className="triangle-svg" */}
                    {/* onMouseEnter={this.mouseEnterHandler} */}
                    {/* onMouseLeave={this.mouseLeaveHandler} */}
          {/* /> } */}
                   {/* Sorry, your browser does not support inline SVG. */}
                 {/* </svg> */}
               {/* </div> */}
               {/* <div */}
          {/* className="dropdown-menu dropdwn" */}
           {/* onMouseEnter={this.mouseEnterHandler} */}
          {/* onMouseLeave={this.mouseLeaveHandler} */}
                 {/* >  */}
                  {/* <ul>  */}
                   {/* <li> */}
                     {/* <a className="menu-item" href="/solution">  */}
                       {/* agroNET  */}
                      {/* </a>  */}
                    {/* </li> */}
                 {/* </ul>  */}
               {/* </div> */}
             {/* </> */}
             {/* )}  */}
           {/* <span */}
           {/* onMouseEnter={this.mouseEnterHandler}  */}
          {/* onMouseLeave={this.mouseLeaveHandler}  */}
            {/* >  */}
            {/* Solutions  */}
            {/* </span>  */}
           {/* </span>  */}
          <a href="/contact" className={`Nav-item ${this.props.selected === 'contact' && "is-active"}`} >
          {/* {this.state.hovered === "contact" || (this.props.selected === 'contact' && this.state.hovered === "") ?
            <SelectionTriangle />
            : null
            } */}
            Contact</a>
            
        </nav>
      </header>
    );
  }
}

export default Header;
